import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit"
import axiosClient from "../../utility/apiClient"
import { RootState } from "../../app/store"

export interface IStatusListingItem {
  status_id: number
  status_name: string
  created_by: string
  created_at: string
  updated_at: string
}

export interface IStatusState {
  loading: boolean
  data: IStatusListingItem[]
}

const initialState: IStatusState = {
  loading: true,
  data: [],
}

export const fetchStatusListing = createAsyncThunk(
  "status/fetchStatus",
  async () => {
    const response = await axiosClient.get(`auth/view-all-status`)
    return response.data
  },
)

const StatusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IStatusState>) => {
    builder.addCase(fetchStatusListing.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.loading = false
    })
  },
})

export const selectStatusListing = (state: RootState) => state.status

export default StatusSlice.reducer
