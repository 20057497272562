import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
export type TUser = "admin" | "employee"
export interface AuthUserResponse {
  iss: string
  iat: number
  exp: number
  nbf: number
  jti: string
  sub: string
  prv: string
  id: number
  user_name: string
  last_login: ""
  user_type: TUser
}
export const userInitialState: { data: AuthUserResponse } = {
  data: {
    iss: "",
    iat: 0,
    exp: 0,
    nbf: 0,
    jti: "",
    sub: "",
    prv: "",
    id: 0,
    user_name: "",
    last_login: "",
    user_type: "employee",
  },
}

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,

  reducers: {
    setloginuser: (state, action: PayloadAction<AuthUserResponse>) => {
      state.data = action.payload
    },
    logoutUser: (state) => {
      state.data = userInitialState.data
    },
  },
})

export const { setloginuser, logoutUser } = userSlice.actions

export const selectCount = (state: RootState) => state.user

export default userSlice.reducer
