import { BarChart, PieChart } from "@mui/x-charts"
import React from "react"

export default function DashCharts() {
  return (
    <div className="d-flex h-80 flex-column flex-align-center flex-justify-space-between">
      <BarChart
        series={[
          { data: [3, 4, 1, 6, 5], stack: "A", label: "Series A1" },
          { data: [4, 3, 1, 5, 8], stack: "A", label: "Series A2" },
          { data: [4, 2, 5, 4, 1], stack: "B", label: "Series B1" },
          { data: [2, 8, 1, 3, 1], stack: "B", label: "Series B2" },
          { data: [10, 6, 5, 8, 9], label: "Series C1" },
        ]}
        width={600}
        height={350}
      />

      <PieChart
        series={[
          {
            data: [
              { id: 0, value: 10, color: "#4e79a7", label: "series A" },
              { id: 1, value: 15, color: "#f28e2c", label: "series B" },
              { id: 2, value: 20, color: "#e15759", label: "series C" },
            ],
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        height={200}
        width={600}
      />
    </div>
  )
}
