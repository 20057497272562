import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import axiosClient from "../../utility/apiClient"

export interface IStudentListingItem {
  student_id: number
  student_name: string
  student_father_name: string
  student_mother_name: string
  student_email: string
  student_contact_no: number
  student_parent_contact_no: number
  student_blongs_to: number
  student_city: string
  exam_appeared: string
  exam_name: string
  exam_marks: number
  enquiry_for: number
  current_assignee: number
  current_status: number
  is_active: string
  created_by: string
  created_at: string
  updated_at: string
}

// Define the initial state
interface IStudentState {
  loading: boolean
  data: IStudentListingItem[]
  rowCount: number
}

const initialState: IStudentState = {
  loading: true,
  data: [],
  rowCount: 0,
}

export const fetchStudnetListingByPage = createAsyncThunk(
  "users/fetchByIdStatus",
  async (pageNumber: number = 1, thunkAPI) => {
    const response = await axiosClient.get(
      `auth/fetch-student-list/${pageNumber}`,
    )
    return response.data
  },
)
// Create a student slice
export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    updateSelection: (
      state,
      action: {
        payload: Array<number>
        type: string
      },
    ) => {
      console.log(action.payload)
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IStudentState>) => {
    builder.addCase(fetchStudnetListingByPage.fulfilled, (state, action) => {
      state.data = action.payload.data.data
      state.rowCount = action.payload.data.total
      state.loading = false
    })
  },
})

// Extract action creators from the slice

// Create a selector to get the student state
export const selectStudentListing = (state: RootState) => state.studnetListing

export const { updateSelection } = studentSlice.actions
// Export the reducer
export default studentSlice.reducer
