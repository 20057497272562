import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import userReducer from "../features/User/userSlice"
import studnetListingReducer from "../features/StudenListing/studentListingSlice"
import snackbarReducer from "../features/Snackbar/snackbarSlice"
import StatusReducer from "../features/Status/StatusSlice"
import EmployeesReducer from "../features/Employees/EmployeesSlice"
export const store = configureStore({
  reducer: {
    user: userReducer,
    studnetListing: studnetListingReducer,
    snackbar: snackbarReducer,
    status: StatusReducer,
    Employees: EmployeesReducer,
  },
  devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
