import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface ISnackbarState {
  open: boolean
  duration: number
  message: string
}

const initialState: ISnackbarState = {
  open: false,
  duration: 3000,
  message: "",
}

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (
      state,
      action: { type: string; payload: { message: string } },
    ) => {
      state.message = action.payload.message
      state.open = true
    },
    clearSnackbar: (state) => {
      state.message = ""
      state.open = false
    },
  },
})

export const { clearSnackbar, setSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
export const selectSnackbar = (state: RootState) => state.snackbar
