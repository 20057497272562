import { LOCALSTORAGEKEYS } from "../Constants/Localstorage.constant"

export const getKeyFromLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "")
  } catch (error) {
    return null
  }
}

export const clearLocalStorage = () => {
  localStorage.removeItem(LOCALSTORAGEKEYS.ACCESS_TOKEN)
  localStorage.removeItem(LOCALSTORAGEKEYS.AUTH_LOGIN)
}
