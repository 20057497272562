import axios from "axios"

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    Accepts: "application/json",
    "Content-Type": "application/json",
  },
})

axiosClient.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem("access_token")
  const isLoggedIn = accessToken ? true : false

  if (isLoggedIn) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return request
})

export default axiosClient
