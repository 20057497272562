import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.scss"
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from "react-router-dom"

import { clearLocalStorage } from "./services/localstorage.service"
import Loader from "./app/Components/Loader/Loader"
import DashCharts from "./app/Pages/Dashboard/DashboardCharts"
import { ThemeProvider } from "@mui/material"
import { theme } from "./theme"
const LazyStudentListing = React.lazy(
  () => import("./app/Components/StudentListing/StudentListing"),
)
const LazyDashboard = React.lazy(
  () => import("./app/Pages/Dashboard/Dashboard"),
)
const LazyStatus = React.lazy(() => import("./app/Components/Status/Status"))
const LazyEmployeesTable = React.lazy(
  () => import("./app/Components/Employees/Employees"),
)
const LazyLoginPage = React.lazy(() => import("./app/Pages/Login/LoginPage"))
const LazyProfile = React.lazy(() => import("./app/Components/Profile/Profile"))

const protectedLoader = ({ request }: LoaderFunctionArgs) => {
  const accessToken = localStorage.getItem("access_token")
  const authLogin = localStorage.getItem("authLogin")

  if (!accessToken || !authLogin) {
    return redirect("/login")
  }

  try {
    const tokenData = JSON.parse(authLogin)
    const currentUnixTime = Math.floor(Date.now() / 1000)

    if (tokenData.exp < currentUnixTime) {
      // Token has expired, redirect to login
      clearLocalStorage()
      return redirect("/login")
    }
    // Token is still valid
    return null
  } catch (error) {
    // Handle JSON parsing error
    console.error("Error parsing token:", error)
    return redirect("/login")
  }
}
const IfUserExist = ({ request }: LoaderFunctionArgs) => {
  if (localStorage.getItem("access_token")) {
    return redirect("/app/dashboard-charts")
  }
  return null
}

const router = createBrowserRouter([
  {
    path: "/",
    loader: protectedLoader,
    children: [
      {
        path: "/",
        loader: IfUserExist,
      },
      {
        path: "/app",
        element: (
          <React.Suspense fallback={<Loader />}>
            <LazyDashboard />
          </React.Suspense>
        ),
        children: [
          {
            path: "dashboard-charts",
            element: <DashCharts />,
          },
          {
            path: "studentlisting",
            element: (
              <React.Suspense fallback={<Loader />}>
                <LazyStudentListing />
              </React.Suspense>
            ),
          },
          {
            path: "profile",
            element: (
              <React.Suspense fallback={<Loader />}>
                <LazyProfile />
              </React.Suspense>
            ),
          },
          {
            path: "status",
            element: (
              <React.Suspense fallback={<Loader />}>
                <LazyStatus />
              </React.Suspense>
            ),
          },
          {
            path: "employee",
            element: (
              <React.Suspense fallback={<Loader />}>
                <LazyEmployeesTable />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <React.Suspense fallback={<Loader />}>
        <LazyLoginPage />
      </React.Suspense>
    ),
    loader: IfUserExist,
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)
