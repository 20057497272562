import { CircularProgress } from "@mui/material"
import React from "react"
import "./Loader.scss"
const Loader: React.FC = () => {
  return (
    <div className="loader full-h full-w flex-align-items-center flex-justify-content-center">
      <CircularProgress />
    </div>
  )
}

export default Loader
