import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit"
import axiosClient from "../../utility/apiClient"
import { RootState } from "../../app/store"

export interface IEmployeesListItem {
  id: number
  name: string
  email: string
  status: string
  user_type_id: number
  user_type: string
}

interface IEmployeesState {
  loading: boolean
  data: IEmployeesListItem[]
}

const initialState: IEmployeesState = {
  loading: true,
  data: [],
}

export const fetchEmployeesListing = createAsyncThunk(
  "Employees/fetch",
  async () => {
    const response = await axiosClient.get(`admin/users`)
    return response.data
  },
)

const EmployeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IEmployeesState>) => {
    builder.addCase(fetchEmployeesListing.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.loading = false
    })
  },
})

export const selectEmployeesListing = (state: RootState) => state.Employees

export default EmployeesSlice.reducer
